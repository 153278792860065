<template>
<div id="app" class="plus home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="en" page="plus" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #1538b4 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <!-- 首图logo -->
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/blog6-logo-3.webp" alt="GB WhatsApp APK" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      WhatsApp Plus
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.20</strong> | Size: <strong>77MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      WhatsApp Plus was developed based on the official WhatsApp which has more than two billions of users across the world. As one of the WhatsApp Mods, it allows users to read deleted messages, hide double ticks, change themes and fonts, set auto repply messages and more custom features.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <router-link class="wp-block-button__link wp-element-button" :to="{name:`${selectedLanguage}-download`}">Download WhatsApp Plus
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <!-- Table of Contents -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> WhatsApp Plus App Detail</a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> Screenshots</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Need of WhatsApp Plus</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> Features of WhatsApp Plus</a>
                      </li>
                      <li>
                        <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> Newly Added Features in 2024</a>
                      </li>
                      <li>
                        <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> WhatsApp Plus vs. Official WhatsApp</a>
                      </li>
                      <li>
                        <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span> WhatsApp Plus Backup</a>
                      </li>
                      <li>
                        <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> Requirements for WhatsApp Plus App</a>
                      </li>
                      <li>
                        <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> How to Download WhatsApp Plus?</a>
                      </li>
                      <li>
                        <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> How to Update WhatsApp Plus?</a>
                      </li>
                      <li>
                        <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> Official Version 18.20 WhatsApp Plus</a>
                      </li>
                      <li>
                        <a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span> Upcoming Version 2025</a>
                      </li>
                      <li>
                        <a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> Older Versions of WhatsApp Plus</a>
                      </li>
                      <li>
                        <a href="#TBC_13"><span class="toc_number toc_depth_12">14.</span> WhatsApp Plus for iPhone</a>
                      </li>
                      <li>
                        <a href="#TBC_14"><span class="toc_number toc_depth_12">15.</span> Download WhatsApp Plus for PC/Laptop</a>
                      </li>
                      <li>
                        <a href="#TBC_15"><span class="toc_number toc_depth_12">16.</span> Pros and Cons of WhatsApp Plus</a>
                      </li>
                      <li>
                        <a href="#TBC_16"><span class="toc_number toc_depth_12">17.</span> User's Reviews</a>
                      </li>
                      <li>
                        <a href="#TBC_17"><span class="toc_number toc_depth_12">18.</span> FAQs</a>
                      </li>
                      <li>
                        <a href="#TBC_18"><span class="toc_number toc_depth_12">19.</span> Final Words</a>
                      </li>
                    </ul>

                  </section>

                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0"> WhatsApp Plus App Detail
                  </span>
                </h2>
                <ul>
                  <li><strong>Size: </strong>77 MB</li>
                  <li><strong>User Base: </strong>2 million users worldwide</li>
                  <li><strong>Update Frequency: </strong>Regular updates every few months</li>
                  <li><strong>Developers: </strong>Community-based developers </li>
                  <li><strong>App Availability: </strong>Android only, not on the Google Play Store</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_1">Screenshot</span>
                </h2>

                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/plus-1.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/plus-1.webp" alt="whatsappplus interface" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_2">Need of WhatsApp Plus</span>
                </h2>

                <p>
                  WhatsApp Plus is widely used by users who seek enhanced customization and privacy features not available in the official WhatsApp. This includes advanced control over chat visibility, appearance modifications, and the ability to use multiple accounts seamlessly.
                </p>

                <h2 class="h">
                  <span id="TBC_3"> Features of WhatsApp Plus
                  </span>
                </h2>

                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">App Lock</h3>
                    <p>
                      Keeps the app secure with PIN, fingerprint, or pattern lock.
                    </p>

                    <h3 class="h3">Chat Lock</h3>
                    <p>
                      Lock individual chats with specific passwords.
                    </p>

                    <h3 class="h3">Ghost Mode</h3>
                    <p>
                      Invisible online status, making it perfect for privacy.
                    </p>

                    <h3 class="h3">DND Mode</h3>
                    <p>
                      Disable internet for WhatsApp Plus alone without affecting other apps.
                    </p>

                    <h3 class="h3">Send Messages Without Saving Number</h3>
                    <p>
                      Send messages to unknown numbers directly.
                    </p>

                    <h3 class="h3">Hide Typing/Recording</h3>
                    <p>
                      Conceal the typing or recording indicator in chats.
                    </p>

                    <h3 class="h3">Change Themes</h3>
                    <p>
                      Access an extensive library of themes for customization.
                    </p>

                    <h3 class="h3">Change Fonts</h3>
                    <p>
                      Modify font styles to match your preference.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Hide Blueticks</h3>
                    <p>
                      Read messages without triggering read receipts.
                    </p>

                    <h3 class="h3">Language Translation</h3>
                    <p>
                      Translate messages in real-time to any supported language.
                    </p>

                    <h3 class="h3">Anti-Deleted Messages</h3>
                    <p>
                      Retain messages deleted by other users.
                    </p>

                    <h3 class="h3">Go to First Message</h3>
                    <p>
                      Jump back to the first message in any chat with a click.
                    </p>

                    <h3 class="h3">Schedule Message</h3>
                    <p>
                      Automate messages for specific times.
                    </p>

                    <h3 class="h3">Auto-Reply Message</h3>
                    <p>
                      Set automatic replies when busy.
                    </p>

                    <h3 class="h3">Unread Message</h3>
                    <p>
                      Highlight unread messages in chats.
                    </p>

                    <h3 class="h3">Download Status</h3>
                    <p>
                      Download photos, videos, and text status updates.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_4"> Newly Added Features in 2024 </span>
                </h2>
                <ul>
                  <li>Ghost Mode</li>
                  <li>Message Edit History</li>
                  <li>Go to the First Message</li>
                  <li>Old UI Interface</li>
                  <li>Night/Light Mode</li>
                  <li>Group Creation Member Selection</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_5">WhatsApp Plus vs. Official WhatsApp</span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Main Feature</th>
                        <th>WhatsApp Plus</th>
                        <th>Official WhatsApp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ghost Mode</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Hide Online Status</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>App Lock</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Who Can Call Me</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Change Themes</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Change Fonts</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Change Ticks Icon</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Auto Reply</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Schedule Message</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Anti Deleted Messages</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Original Images</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>Freeze Last Seen</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>Customer Service</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>System</td>
                        <td>Android</td>
                        <td>Android, Web, Desktop, iOS</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_6"> WhatsApp Plus Backup and Restore</span>
                </h2>

                <p>
                  The official WhatsApp allows users to backup their data based on different frequency to Google Drive directly. However, WhatsApp Plus APK is a modified version. It can't access Google Drive.
                </p>
                <p>
                  Users of WhatsApp Plus can go to the file manager of their Android phones, find the location of WhatsApp Plus folder. Select database file and upload it to Google Drive.
                </p>
                <p>
                  Then, how to restore the data to the official WhatsApp? You can replace the official database file with the one of WhatsApp Plus. Then, it works. For more details, you can read our blogs.
                </p>

                <h2 class="h">
                  <span id="TBC_7"> Requirements for WhatsApp Plus App </span>
                </h2>
                <ul>
                  <li><strong>System: </strong>Android OS 5.0 and above</li>
                  <li><strong>Storage: </strong>At least 200 MB free space</li>
                  <li><strong>No Root Required</strong></li>
                  <li><strong>A WhatsApp-registered number</strong></li>
                </ul>

                <h2 class="h">
                  <span id="TBC_8"> How to Download WhatsApp Plus? </span>
                </h2>
                <ol>
                  <li>Step 1: Enable <strong>Install Unknown Source</strong> under special permission of apps.</li>
                  <li>Step 2: Download the WhatsApp Plus APK from a trusted source.</li>
                  <li>Step 3: Install the APK on your Android device.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_9"> How to Update WhatsApp Plus? </span>
                </h2>
                <p>
                  Updates fix crashes and bugs, strengthen anti-ban protocols, and introduce new features for improved performance. Here you can choose two methods to update WhatsApp Plus.
                </p>
                <ul>
                  <li><strong>Method 1: </strong>Download the latest version of the APK file from a reliable site and install it to keep your app up-to-date.</li>
                  <li><strong>Method 2: </strong>Check if the app notices you updating the version. So, you can update directly.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_10"> Official Version 18.20 WhatsApp Plus </span>
                </h2>
                <p>
                  Version 18.20 is the latest, providing enhanced customization, privacy, and optimized performance. It enhanced anti-ban protection and fixed some crashes.
                </p>
                <p>
                  A guide to link WhatsApp Plus as a companion device of WhatsApp Web was also added. You can check the guide to login to your account without being banned.
                </p>

                <h2 class="h">
                  <span id="TBC_11"> Upcoming WhatsApp Plus 2025
                  </span>
                </h2>
                <p>
                  Users are anticipating that the developers will remove limitations on account usage of WhatsApp Plus 2025 imposed by the official WhatsApp.
                </p>

                <h2 class="h">
                  <span id="TBC_12"> Older Versions of WhatsApp Plus </span>
                </h2>
                <p>
                  Notable previous versions include v18.10, v18.00, v17.85, which introduced significant features and updates.
                </p>

                <h2 class="h">
                  <span id="TBC_13"> WhatsApp Plus for iPhone </span>
                </h2>
                <p>
                  Currently, WhatsApp Plus is unavailable on iOS due to app store restrictions. So, users have to use WhatsApp Plus app on Android.
                </p>

                <h2 class="h">
                  <span id="TBC_14"> Download WhatsApp Plus for PC/Laptop </span>
                </h2>
                <p>
                  SInce WhatsApp Plus is not availale for iPhone, smart users find indirect way to install WhatsApp Plus APK on PC (window7,8,10,11).
                </p>
                <p>
                  Use Bluestacks or any high quality Android emulator can help you to install WhatsApp Plus on PC. To install the app successfully, you should read the user guide of Bluestacks first.
                </p>

                <h2 class="h">
                  <span id="TBC_15"> Pros and Cons of WhatsApp Plus </span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Pros</th>
                        <th>Cons</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Custom Features to enrich messaging experience</td>
                        <td>Not officially supported, potential security risks, account ban risks.</td>
                      </tr>
                      <tr>
                        <td>More Privacy Options to protect privacy of users</td>
                        <td>Account Ban Risks</td>
                      </tr>
                      <tr>
                        <td>Free to download and install the app. No hidden fee.</td>
                        <td>Companion Mode be limited by the official</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_16"> Users' Reviews </span>
                </h2>
                <ol>
                  <li><strong>Alice: </strong>"Great for customization, really enjoy the themes!"</li>
                  <li><strong>John: </strong>"Finally found a way to control who sees me online"</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_17"> FAQs </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Is WhatsApp Plus safe to use?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      It's unofficial and may carry security risks, but it was widely used across the world.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Can I use WhatsApp Plus and official WhatsApp on the same device?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      Yes, with dual apps support on Android.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_18"> Final Words</span>
                </h2>
                <p>
                  WhatsApp Plus is an attractive choice for users seeking a more customizable messaging experience, but users should consider potential security risks.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="snippet-box">
    <div class="snippet-title">
      Summary
    </div>

    <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
      <div class="aio-info">
        <div class="snippet-label-img snippet-clear">
          Author Rating
        </div>
        <div class="snippet-data-img">
          <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"></span>
        </div>
        <div class="snippet-clear" />
        <div class="aggregate_sec" itemprop="aggregateRating" itemscope="" itemtype="https://schema.org/AggregateRating">
          <div class="snippet-label-img">
            Aggregate Rating
          </div>
          <div class="snippet-data-img">
            <span itemprop="ratingValue">3.65</span> based on
            <span class="rating-count" itemprop="ratingCount">46258</span>
            votes
          </div>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Software Name
        </div>
        <div class="snippet-data-img">
          <span itemprop="name">GBWhatsApp APK</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Operating System
        </div>
        <div class="snippet-data-img">
          <span itemprop="operatingSystem">Android</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Software Category
        </div>
        <div class="snippet-data-img">
          <span itemprop="applicationCategory">App</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Price
        </div>
        <div class="snippet-data-img">
          <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
            <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Landing Page
        </div>
        <div class="snippet-data-img">
          <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
        </div>
        <div class="snippet-clear" />
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="/privacy">Privacy Policy</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong>
            <router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link>
          </strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="goto('download')">
    Download GBWhatsApp New
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'en-download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatapks.com',
          appName: 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
